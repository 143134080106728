import './App.css';
import About from './componenets/about-us';
import Banner from './componenets/banner';
import Contact from './componenets/contact-us';
import Navbar from './componenets/navbar'
import Screen from './componenets/screen';
import Services from './componenets/services';
import Tech from './componenets/tech';

function App() {
  return (
    <>
    <Navbar />
    <Banner/>
    <Services/>
    <Tech/>
    <Screen/>
    <About/>
    <Contact/>
    </>
  );
}

export default App;
