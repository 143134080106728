import React from 'react'


function Navbar() {
    return (
        <>
            <nav className="navbar navbar-expand-lg bg-body-tertiary" >
                <div className="container">
                    <a className="navbar-brand" href="#"><img src='img/logo.png'></img></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"><img src='img/menu.png'></img> </span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            <a className="nav-link active" aria-current="page" href="#home">Home</a>
                            <a className="nav-link" href="#service">Services</a>
                            <a className="nav-link" href="#technology">Technology</a>
                            <a className="nav-link" href="#screen">Work</a>
                            <a className="nav-link" href="#about">About us</a>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar