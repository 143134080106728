import React from "react";

function Services() {
    return (
        <>
            <div className="services container" id="service">
                <h1>Services</h1>
                <div className="row container g-md-4 gx-3 gy-4">
                    <div className="col-md-4 col-12">
                        <div className="services1">
                            <img src="img/icons8-web-design-64.png"></img>
                            <h2>UI/UX Design</h2>
                            <p>Provide unique and compelling UX/UI design for Web, Software and Mobile Applications using up-to-date tools with 2D and 4D design effects.</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="services1">
                            <img src="img/icons8-app-development-64.png"></img>
                            <h2>Mobile App Development</h2>
                            <p>Our app developers are experts in all technology Android, iOS, React Native etc. to help you scale your business on both the platforms and maximize your reach.</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="services1">
                            <img src="img/icons8-software-66.png"></img>
                            <h2>Software Development </h2>
                            <p>Our developers can create custom software for your business.</p>
                        </div>
                    </div>
                </div>
                <div className="row container g-md-4 gx-3 gy-4">
                    <div className="col-md-4 col-12">
                        <div className="services1">
                            <img src="img/digital-marketing.png"></img>
                            <h2>Digital Marketing</h2>
                            <p>Elevate your online presence with our expert services—strategic social media, data-driven SEO, and more. Let's turn visions into victories together.</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="services1">
                            <img src="img/icons8-web-development-66.png"></img>
                            <h2>Dedicated Developers</h2>
                            <p>We provide skilled, experienced dedicated developers and software engineers.</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="services1">
                            <img src="img/icons8-web-development-64.png"></img>
                            <h2>Web Development</h2>
                            <p>Our developers can create custom website for your business needs to boost your online presence.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Services