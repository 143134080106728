import React from "react";

function About() {
    return (
        <>
            <div className="container" id="about">
                <div className="row about-us">
                    <div className="col-md-6 col-12 about-img">
                        <img src="img/About us.png"></img>
                    </div>
                    <div className="col-md-6 col-12 about">
                        <h1>About Us</h1>
                        <div>
                            <h6>Pramukh Soft is a global IT solution company that develops digital products, platforms, and experiences for their clients that engage users and scale on demand.</h6>
                            <p><b>Purpose:</b> We empower our global partners to achieve great outcomes with technology.</p>
                            <p><b>Mission:</b> To connect the global IT ecosystem and unlock its potential for all.</p>
                            <p><b>Vision:</b> We deliver compelling technology solutions to a dynamic global marketplace.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About