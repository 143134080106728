import React from "react";

function Tech() {
    return (
        <>
            <div className="container technology" id="technology">
                <h1>Technology Stack</h1>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-12">
                            <div className="nav flex-md-column flex-row nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Frontend</button>
                                <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Backend</button>
                                <button className="nav-link" id="v-pills-disabled-tab" data-bs-toggle="pill" data-bs-target="#v-pills-disabled" type="button" role="tab" aria-controls="v-pills-disabled" aria-selected="false">Database</button>
                                <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Mobile</button>
                                <button className="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Cloud</button>
                            </div>
                        </div>
                        <div className="col-md-9 col-11 d-flex justify-content-center align-items-center">
                            <div className="tab-content " id="v-pills-tabContent">
                                <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabIndex="0">
                                    <div className="tech row">
                                        <div className="tech1 col-md-2 col-11">
                                            <img src="img/angularjs.svg"></img>
                                            <h6>Angular JS</h6>
                                        </div>
                                        <div className="tech1 col-md-2 col-11">
                                            <img src="img/react-native.svg"></img>
                                            <h6>React JS</h6>
                                        </div>
                                        <div className="tech1 col-md-2 col-11">
                                            <img src="img/vue-js.svg"></img>
                                            <h6>Vue JS</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabIndex="0">
                                    <div className="tech row">
                                        <div className="tech1 col-md-2 col-11">
                                            <img src="img/python.svg"></img>
                                            <h6>Python</h6>
                                        </div>
                                        <div className="tech1 col-md-2 col-11">
                                            <img src="img/nodejs-svg.svg"></img>
                                            <h6>Node JS</h6>
                                        </div>
                                        <div className="tech1 col-md-2 col-11">
                                            <img src="img/PHP.svg"></img>
                                            <h6>PHP</h6>
                                        </div>
                                        <div className="tech1 col-md-2 col-11">
                                            <img src="img/dotnet-icon.svg"></img>
                                            <h6>.NET</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="v-pills-disabled" role="tabpanel" aria-labelledby="v-pills-disabled-tab" tabIndex="0">
                                    <div className="tech row">
                                        <div className="tech1 col-md-2 col-11">
                                            <img src="img/icons8-my-sql-96.png"></img>
                                            <h6>MY SQl</h6>
                                        </div>
                                        <div className="tech1 col-md-2 col-11">
                                            <img src="img/sql-60.png"></img>
                                            <h6>SQL</h6>
                                        </div>
                                        <div className="tech1 col-md-2 col-11">
                                            <img src="img/SQL-server.svg"></img>
                                            <h6>Microsoft SQLserver</h6>
                                        </div>
                                        <div className="tech1 col-md-2 col-11">
                                            <img src="img/postgre.svg"></img>
                                            <h6>PostgreSQL</h6>
                                        </div>
                                        <div className="tech1 col-md-2 col-11">
                                            <img src="img/mongodb.svg"></img>
                                            <h6>Mongo DB</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab" tabIndex="0">
                                    <div className="tech row">
                                        <div className="tech1 col-md-2 col-11">
                                            <img src="img/android.svg"></img>
                                            <h6>Android</h6>
                                        </div>
                                        <div className="tech1 col-md-2 col-11">
                                            <img src="img/apple-ios-svg.svg"></img>
                                            <h6>IOS</h6>
                                        </div>
                                        <div className="tech1 col-md-2 col-11">
                                            <img src="img/fulter.svg"></img>
                                            <h6>Flutter</h6>
                                        </div>
                                        <div className="tech1 col-md-2 col-11">
                                            <img src="img/react-native.svg"></img>
                                            <h6>React native</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab" tabIndex="0">
                                    <div className="tech row">
                                        <div className="tech1">
                                            <img src="img/aws.svg"></img>
                                            <h6>AWS</h6>
                                        </div>
                                        <div className="tech1">
                                            <img src="img/icons8-azure-80.png"></img>
                                            <h6>Microsoft Azure</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default Tech