import React from "react";

function Banner() {
    return (
        <>
            <div className="container">
                <div className="row main-banner" id="home">
                    <div className="col-md-6 col-12">
                        <div className="banner">
                            <p>Global IT Solution</p>
                            <h1>PRAMUKH <br /> SOFT</h1>
                            <h5>An IT Solutions Provider Shaping Digital Success Stories of Pramukh Soft</h5>
                            <a href="#service"><button className="btn1">Explore now</button></a>
                        </div>
                    </div>
                    <div className="col-md-6 col-12 banner-img">
                        <img src="img/banner.png"></img>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Banner