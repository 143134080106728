import React from "react";

function Contact() {
    return (
        <>
            <div className="footer">
                <div className="row container g-4">
                    <div className="col-lg-3 col-md-4 col-12 logo-footer">
                        <a href="#home"><img src="img/logo-footer.png"></img></a>
                    </div>
                    <div className="col-lg-3 col-md-4 col-12 quick-link">
                        <h5>Quick Link</h5>
                        <div className="quick">
                            <a href="#home">Home</a>
                            <a href="#service">Services</a>
                            <a href="#technology">Technology</a>
                            <a href="#screen">Work</a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-12 contact-us">
                        <h5>Contact Us</h5>
                        <div className="contact">
                            <img src="img/icons8-visit-100.png"></img>
                            <p>Infocity, Gandhinagar, Gujarat-382007</p>
                        </div>
                        <div className="contact">
                            <img src="img/icons8-envelope-100.png"></img>
                            <p>info@pramukhsoft.com</p>
                        </div>
                        <div className="contact">
                            <img src="img/icons8-phone-100.png"></img>
                            <p>+9194 2768 5363</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-12 d-block follow">
                        <h5>Follow US</h5>
                        <div className="follow-us">
                            <img src="img/icons8-facebook-100.png"></img>
                            <img src="img/icons8-linkedin-100.png"></img>
                            <img src="img/icons8-skype-100.png"></img>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <p>© 2022 Copyright : info@pramukhsoft.com</p>
                </div>
            </div>
            <a href="#button" className="scroll-top on" role="button">
              <span>
              <i className="fas fa-arrow-up"></i>
              </span>
            </a>
        </>
    )
}

export default Contact